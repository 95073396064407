import React from "react"
import { Link, graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  BlogPostsByTagQuery,
  BlogPostsByTagQueryVariables,
} from "../../graphql-types"

// TODO: これでいい？
type NodeType = BlogPostsByTagQuery["allMdx"]["nodes"]
type PostListProps = {
  posts: NodeType
}

const PostList: React.FC<PostListProps> = ({ posts }) => {
  return (
    <ol>
      {posts.map(post => {
        const { slug, title, date } = post.frontmatter!
        return (
          <li key={slug}>
            <Link to={slug!}>
              {title} ({date})
            </Link>
          </li>
        )
      })}
    </ol>
  )
}

type Props = PageProps<BlogPostsByTagQuery, BlogPostsByTagQueryVariables>

const Tag: React.FC<Props> = ({ pageContext, data }) => {
  const tag = pageContext.tag!
  const { nodes, totalCount } = data.allMdx

  const tagHeader = `found ${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  const meta = [
    {
      name: "robots",
      content: "noindex",
    },
  ]

  return (
    <Layout>
      <SEO title={tag} meta={meta} />
      <h2>{tagHeader}</h2>
      <PostList posts={nodes} />
    </Layout>
  )
}
export default Tag

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          slug
          title
          date(formatString: "YYYY/MM/DD")
        }
      }
    }
  }
`
